'use client';

import { useRef } from 'react';

import { submitContactForm } from '@/actions/actions';

import styles from './Form.module.css';

const Form = () => {
  const ref = useRef<HTMLFormElement>(null);
  return (
    <div className={styles.container}>
      <form
        ref={ref}
        id="contact-form"
        className={styles.form}
        action={async (formData) => {
          await submitContactForm(formData);
          ref.current?.reset();
        }}
      >
        <input
          className={styles.name}
          type="text"
          name="name"
          placeholder="שם"
          required
        />
        <input
          className={styles.email}
          type="email"
          name="email"
          placeholder="אימייל"
          required
        />
        <textarea
          className={styles.message}
          name="message"
          placeholder="תוכן ההודעה"
          required
        />
      </form>
    </div>
  );
};

export default Form;
