// Import Swiper styles

'use client';

import 'swiper/css';
import './styles.css';

import type { FC } from 'react';
import { Swiper as SwiperLib, SwiperSlide } from 'swiper/react';

import type { Product } from '@/types/product.types';

import ProductCard from '../ProductCard/ProductCard';
import type { ISwiperProps } from './Swiper.types';

const Swiper: FC<ISwiperProps> = ({ products }) => {
  return (
    <SwiperLib
      spaceBetween="10px" // Set the space between slides to 8px
      slidesPerView="auto" // Adjust to 'auto' for flexible slide sizes
      centeredSlides={false}
    >
      {products.map((product: Partial<Product>) => {
        return (
          <SwiperSlide key={product.id}>
            <ProductCard {...product} lastAdded />
          </SwiperSlide>
        );
      })}
    </SwiperLib>
  );
};

export default Swiper;
